const menu = document.querySelector(".menu");
const body = document.querySelector("body");
const header = document.querySelector(".header");
const menuTriggers = [...document.querySelectorAll(".menu-trigger")];
const closeTriggers = [...document.querySelectorAll(".menu-close")];

menuTriggers.forEach((trigger) => {
	trigger.addEventListener("click", () => {
		menu.classList.add("menu--open");
		body.classList.add("is-fixed");
		header.classList.add("fixed");
	});
});

closeTriggers.forEach((trigger) => {
	trigger.addEventListener("click", () => {
		menu.classList.remove("menu--open");
		body.classList.remove("is-fixed");
		header.classList.remove("fixed");
	});
});
