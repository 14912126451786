const triggers = [...document.querySelectorAll(".program__activities-trigger")];

if (triggers?.length) {
  triggers.forEach((trigger) => {
    const accordionContent = trigger.previousElementSibling;
    const title = trigger.querySelector(".program__activity-title");

    trigger.addEventListener("click", (e) => {
      e.preventDefault();
      accordionContent.classList.toggle("active");
      trigger.style.display = "none";
    });
  });
}
