const dvdLogo = document.querySelector(".menu__flower");

let x = 0;
let y = 0;
let xSpeed = 2;
let ySpeed = 2;
let width = 398;
let height = 529;

if (window.innerWidth < 960) {
	width = 123;
	height = 163;
}

const startAnimation = () => {
	function moveDVD() {
		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;

		x += xSpeed;
		y += ySpeed;

		if (x + width > screenWidth || x < 0) {
			xSpeed *= -1;
		}

		if (y + height > screenHeight || y < 0) {
			ySpeed *= -1;
		}

		dvdLogo.style.transform = `translate(${x}px, ${y}px)`;

		requestAnimationFrame(moveDVD);
	}

	moveDVD();
};

startAnimation();
