import {timeline} from "./animation";
import {
  replaceImages,
  replaceContent,
  getClickedArtistData,
  preloadImages,
} from "./utils";

const COLLAGE_DATA = window.COLLAGE_DATA;
const COLLAGE = document.querySelector(".collage");

if (COLLAGE) {
  const artists = [...document.querySelectorAll(".collage-artist")];
  const exit = document.querySelector(".collage__close");
  const preloadImagesUrls = COLLAGE_DATA.reduce(
    (acc, data) => [...acc, ...Object.values(data.images)],
    []
  );

  preloadImages(preloadImagesUrls);

  if (document.querySelector('[data-template="artist"]')) {
    timeline.play();
  }
  artists.forEach((artist) => {
    const {
      artistName,
      artistCountry,
      artistRichText,
      images,
      artistDate,
      artistTime,
      artistScene,
      artistSpotify,
    } = getClickedArtistData(artist);

    artist.addEventListener("click", () => {
      replaceContent({
        name: artistName,
        country: artistCountry,
        richText: artistRichText,
        date: artistDate,
        time: artistTime,
        scene: artistScene,
        spotify: artistSpotify,
      });

      replaceImages(images);
      timeline.play();
    });
  });

  exit.addEventListener("click", () => {
    timeline.reverse();
  });
}
