import {gsap} from "gsap";
import {toggleCollageStyles, toggleScrollBehaviour} from "./utils";

const collage = document.querySelector(".collage");
const collageSection = document.querySelector(".collage__section");
const image1 = document.querySelector(".collage-image--1");
const image2 = document.querySelector(".collage-image--2");
const image3 = document.querySelector(".collage-image--3");
const image4 = document.querySelector(".collage-image--4");
const image5 = document.querySelector(".collage-image--5");

const timelineDefaults = {
  paused: true,
  defaults: {
    duration: 0,
    delay: 0.08,
  },
};

export const timeline = gsap.timeline({
  ...timelineDefaults,
  onStart: () => {
    toggleCollageStyles({
      state: "add",
    });
    toggleScrollBehaviour({
      state: "add",
    });
  },
  onComplete: () => {
    collage.style.pointerEvents = "auto";
    collageSection.style.pointerEvents = "auto";
    toggleScrollBehaviour({
      state: "remove",
    });
  },
  onReverseComplete: () => {
    collage.style.display = "none";
    collageSection.style.display = "none";

    toggleScrollBehaviour({
      state: "remove",
    });
  },
});

timeline
  .to(image2, {opacity: 1})
  .to(image1, {opacity: 1})
  .to(image1, {rotation: -10})
  .to(image3, {opacity: 1}, "<")
  .to(image2, {scale: 1.1})
  .to(image3, {rotation: -10}, "<")
  .to(image1, {rotation: 0}, "<")
  .to(image2, {scale: 1})
  .to(image4, {opacity: 1}, "<")
  .to(image5, {opacity: 1}, "<");
