import {gsap} from "gsap";
import SplitType from "split-type";
import {CustomEase} from "gsap/CustomEase";
gsap.registerPlugin(CustomEase);

import EventEmitter from "events";

export default class Preloader extends EventEmitter {
  constructor() {
    super();
    this.preloader = document.querySelector(".preloader__section");
    this.preloader.style.display = "block";
    this.marquee = document.querySelector(".preloader__marquee");
    this.line = this.preloader.querySelector(".preloader__line");
    this.title = this.preloader.querySelector(".preloader__text");
    this.progress = this.preloader.querySelector(".preloader__progress");
    this.percentNumber = this.preloader.querySelector(".preloader__percent");
    this.splitted = new SplitType(this.title, {types: "words, chars"});
    this.imageURLS = window.COLLAGE_DATA.reduce(
      (acc, data) => [...acc, ...Object.values(data.images)],
      []
    );
    this.images = this.imageURLS.map((imageURL) => {
      const img = new Image();
      img.src = imageURL;
      return img;
    });

    this.imagesLoaded = 0;
    this._createLoader();
  }

  _createLoader() {
    if (!this.images.length) {
      this.percentNumber.innerHTML = "100";
      return this._onLoaded();
    }

    gsap.set(this.splitted.chars, {
      y: "random(-10, -20)",
      x: "random(-20, 20)",
      rotate: "random(-15, 15)",
    });

    gsap.to(this.splitted.chars, {
      y: 0,
      x: 0,
      rotate: 0,
      stagger: 0.3,
      duration: 0.1,
    });

    gsap.to(this.percentNumber, {
      duration: 5,
      textContent: 100,
      snap: "textContent",
      onComplete: () => {
        gsap
          .timeline({
            onComplete: () => localStorage.setItem("pageLoaded", true),
          })
          .to([this.marquee, this.progress], {
            autoAlpha: 0,
          })
          .to(this.splitted.chars, {
            autoAlpha: 0,
            stagger: 0.05,
          })
          .to(this.preloader, {
            yPercent: -100,
            duration: 2,
            ease: "expo.inOut",
          })
          .fromTo(
            ".hero__artists .artist",
            {
              autoAlpha: 0,
              y: 64,
            },
            {
              autoAlpha: 1,
              y: 0,
              ease: "expo.inOut",
              duration: 1,
              stagger: 0.05,
            },
            "-=1.5"
          )
          .set(this.preloader, {
            display: "none",
          });
      },
      ease: CustomEase.create(
        "custom",
        "M0,0 C0.063,0.191 0.336,0.402 0.438,0.432 0.719,0.515 0.587,0.799 0.68,0.888 0.773,0.977 0.909,1 1,1 "
      ),
    });
    this.images.forEach((image) => {
      image.onload = () => this._onImageLoaded();
    });
  }

  _onImageLoaded() {
    this.imagesLoaded += 1;
    const percentage = this.imagesLoaded / this.images.length;

    if (percentage === 1) {
      localStorage.setItem("pageLoaded", true);
      this._onLoaded();
    }
  }

  _onLoaded() {
    this.emit("completed");
  }

  destroy() {
    this.preloader.parentNode.removeChild(this.preloader);
  }
}
