import "@/styles/index.scss";
import "./utils/scroll";
import "./modules/Menu";
import "./modules/DVDPlayer";
import "./modules/DVDPlayerBye";
import "./modules/Collage";
import "./modules/ProgramAccordion";
import {gsap} from "gsap";
import Preloader from "./components/Preloader";

class App {
  constructor() {
    if (import.meta.hot) {
      import.meta.hot.accept(() => {
        console.log("HMR");
      });
    }

    this._setHeaderHeight();
    this._createPreloader();
    this._addEventListeners();
    this._render();
  }

  _createStats() {
    this.stats = new Stats();
    document.body.appendChild(this.stats.dom);
  }

  _createLenis() {
    this.lenis = new Lenis({
      lerp: 0.15,
    });

    window.LENIS = this.lenis;
  }

  _createPreloader() {
    const preloaderSection = document.querySelector(".preloader__section");
    const isIndex = document.querySelector('[data-template="index"]');
    const pageHasLoadedBefore = localStorage.getItem("pageLoaded") === "true";

    if (isIndex && !pageHasLoadedBefore) {
      this.preloader = new Preloader();
    } else if (isIndex) {
      gsap.to(".hero .artist", {
        autoAlpha: 1,
        stagger: {
          from: "random",
          each: 0.05,
        },
        duration: 0.05,
      });
      preloaderSection.style.display = "none";
    }
  }

  _setHeaderHeight() {
    const header = document.querySelector("header");
    let headerHeight = header.offsetHeight;

    if (headerHeight == 0) {
      headerHeight = 165;
    } else {
      headerHeight = header.offsetHeight;
    }
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerHeight}px`
    );
  }

  _onMouseMove(event) {
    if (this.webGL && this.webGL.onMouseMove) {
      this.webGL.onMouseMove(event);
    }
  }

  _onResize() {
    this._setHeaderHeight();
  }

  _addEventListeners() {
    window.addEventListener("resize", this._onResize.bind(this));
    window.addEventListener("mousemove", this._onMouseMove.bind(this));
  }

  _render(time) {
    if (this.stats) {
      this.stats.begin();
    }

    if (this.webGL && this.webGL.update) {
      this.webGL.update({
        lenis: this.lenis,
      });
    }

    window.requestAnimationFrame(this._render.bind(this));
  }
}

new App();

console.log(
  "%c Developed by ANTI - https://anti.as/",
  "background: #000; color: #fff;"
);
